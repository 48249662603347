
.freight-detail{
    padding: 0 20px;
    .freight-title {
        font-size: 20px;
        padding-left: 15px;
        line-height: 1;
        position: relative;
        display: flex;
        align-items: center;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 5px;
            height: 20px;
            background: #2DC079;
            border-radius: 3px;
            transform: translateY(-50%);
        }
    }
    .freight-detail-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .btn-back{
            margin-top: -5px;
        }
    }
}
